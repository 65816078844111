@media (max-width: @screen-md) {
    .footer-row {
        .footer-column {
            &:nth-of-type(2) {
                border-right: none;
            }
        }
    }
}

@media (max-width: @screen-sm) {
    .footer-row {
        .footer-column {
            .footer-title {
                font-size: 1em;
            }
        }
    }
}

@media (max-width: @screen-xs) {
    .footer {
        .footer-row {
            .footer-column {
                height: initial;
                &:last-of-type {
                    margin-bottom: 30px;
                }
            }
        }
    }
}
