@media (max-width: @screen-sm) {
    .user-details-fill-wrapper {
        .user-details-form {
            .order-totals {
                padding-bottom: 20px;
            }
            .order-price {
                display: inline;
            }
        }
    }
}
