.step {
    .cart {
        padding: 2em 0;
    }
    .step-actions {
        .discount-form {
            margin-bottom: 1em;
        }
        padding: 0;
    }
    .row-even();
    .title-element-texts(2.4em);
    .ticket-type-row {
        padding: 0.5em 0;
    }
    .modal-backdrop {
        .modal-backdrop();
    }
}
