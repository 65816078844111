.bootstrap-datetimepicker-widget {
    td {
        &.active,
        &.active:hover {
            background-color: @brand-tickets-main;
        }

        &.today {
            &:before {
                border-bottom: 7px solid @brand-tickets-main;
            }
        }
        span {
            &.active {
                background-color: @brand-tickets-main;
            }
        }
    }
}
.rtl {
    .bootstrap-datetimepicker-widget {
        .timepicker {
            direction: ltr;
        }
    }
}
