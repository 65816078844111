.payment-type-selection-wrapper {
    .default-inner-wrapper();;
    .disclaimer {
        padding-bottom: 2rem;
    }
    .invoice {
        text-align: center;
        input {
            margin-left: 5px;
        }
    }
    .cart-summary {
        .panel-title {
            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
    .country-payment-types {
        .country-name {
            .country-selector {
                width: 200px;
            }
            .dashed-trailing-line();
        }
        .payment-type-list {
            .payment-type {
                cursor: pointer;
                overflow: hidden;
                height: 150px;
                text-align: center;
                &:hover {
                    background: @brand-tickets-default-darken;
                }
                line-height: 150px;
                .type-info {
                    vertical-align: middle;
                    display: inline-block;
                    line-height: 1em;
                    .payment-choice-logo {
                        margin: 0 auto;
                    }
                    .payment-choice-widget {
                        display: none;
                    }
                    .payment-choice-label {
                        display: block;
                        cursor: pointer;
                        text-align: center;
                        padding-top: 15px;
                    }
                }
            }
        }
    }
    .paysera-disclaimer {
        padding-bottom: 20px;
        font-size: 0.85em;
    }
}
