@event-search-form-element-height: 45px;

.search-wrapper {
    min-height: 50px;
    .event-search-form {
        .form-container {
            padding: 30px 0;
            .form-inline {
                .form-group {
                    padding-right: 26px;
                    &.search-field-date_from,
                    &.search-field-date_to {
                        .input-group {
                            .form-control {
                                width: 95px;
                            }
                        }
                    }
                    &.search-field-search {
                        .input-group {
                            .btn {
                                padding: 10px @padding-base-horizontal;
                                .fa {
                                    padding-right: 11px;
                                }
                            }
                        }
                    }
                    .input-group {
                        .input-group-addon {
                            background: #FFFFFF;
                            color: @brand-tickets-secondary;
                            border-color: @brand-tickets-default-darken;
                        }
                        .form-control {
                            border-left: none;
                            padding: 10px 0;
                            width: 175px;
                            height: auto;
                            color: @brand-tickets-secondary;
                            border-color: @brand-tickets-default-darken;
                            text-overflow: ellipsis !important;
                            .box-shadow(none);
                            &:focus {
                                border-color: @brand-tickets-default-darken;
                            }
                        }
                        .select2 {
                            .select2-selection {
                                border-color: @brand-tickets-default-darken;
                                border-radius: 0;
                                border-left: none;
                                padding: 10px 0;
                                height: auto;
                                .select2-selection__rendered {
                                    color: @brand-tickets-secondary;
                                    padding-right: @padding-base-horizontal;
                                    line-height: inherit;
                                    padding-left: 0;
                                }
                            }
                            .select2-selection__arrow {
                                display: none;
                            }
                        }
                    }
                    .error-message {
                        position: absolute;
                    }
                    &:last-of-type {
                        padding-right: 0;
                    }
                }
            }
        }

        &.select2-dropdown {
            min-width: 300px;
            .select2-results {
                .autocomplete-item {
                    .item-date,
                    .item-location {
                        font-size: 1.2rem;
                    }
                    .item-location {
                        .ellipsis();
                    }
                }
            }
        }

        .collapse-button {
            padding: 15px 0;
            text-align: center;
        }
    }
}

.rtl {
    .search-wrapper {
        .event-search-form {
            .form-container {
                .form-inline {
                    .form-group {
                        padding-left: 23px;
                        padding-right: 0;
                        .input-group {
                            border-left: 1px solid @brand-tickets-default-darken;
                            border-right: 1px solid @brand-tickets-default-darken;

                            .form-control {
                                border-right: none;
                                padding-top: 6px;
                                padding-bottom: 6px;

                                input {
                                    margin-right: 1px;
                                }
                            }
                        }

                        .select2 {
                            .select2-selection {
                                border-right: none;
                            }
                        }
                        &.search-field-search {
                            .input-group {
                                .btn {
                                    padding: 10px @padding-base-horizontal;
                                    .fa {
                                        padding-left: 11px;
                                        padding-right: 0;
                                    }
                                }
                            }
                        }
                        &:last-of-type {
                            padding-left: 0;
                            padding-right: 10px;
                        }
                    }
                }
            }
        }
    }
}

body {
    .select2-container {
        .select2-dropdown {
            z-index: @zindex-dropdown;
        }
    }
}
