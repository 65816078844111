.localization-wrapper {
    height: 40px;
    line-height: 40px;
    .localization-items {
        .localization-item {
            margin-right: 15px;
            display: inline-block;
            &:last-of-type {
                margin-right: 0;
            }
        }
    }
    .modal {
        line-height: normal;
        .select2 {
            .select2-selection {
                border-color: @brand-tickets-default-darken;
                border-radius: 0;
                height: auto;
            }
        }
        .flag-icon {
            margin-right: 5px;
        }
    }
}

.rtl {
    .localization-wrapper {
        .localization-items {
            .localization-item {
                margin-right: auto;
                margin-left: 15px;
                &:last-of-type {
                    margin-left: 0;
                }
            }
        }
        .modal {
            .flag-icon {
                margin-right: auto;
                margin-left: 5px;
            }
        }
    }
}
