.invoice-details-fill-wrapper {
    .default-inner-wrapper();
    .disclaimer {
        padding-bottom: 2rem;
    }
}

.btn-skip-invoice {
    padding-top: 15px
}

.invoice {
    label {
        font-size: 1.5rem;
    }
    input[type=checkbox] {
        zoom: 1.6;
        position: relative;
        bottom: -3px;
    }
}
