.featured-events {
    padding-top: 2em;
}
.carousel {
    position: relative;
    .carousel-indicators {
        bottom: 0;
        left: 0;
        margin-left: 0;
        padding-left: 0;
        width: 100%;
    }
}
.controllers-wrapper {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    .controllers {
        position: relative;
        .carousel-control {
            bottom: auto;
        }
    }
}
.carousel-control.left,
.carousel-control.right {
    background-image: none;
    i {
        line-height: 0;
    }
}
.carousel-caption {
    position: relative;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    padding-top: 0;
    padding-bottom: 0;
    color: black;
    text-align: left;
    text-shadow: none;
    &.btn {
        text-shadow: none;
    }
    .gallery-event {
        text-align: right;
        padding: 20px 40px 20px 0;
        .event-short-info {
            min-height: 220px;
            &.no-price {
                min-height: 271px;
            }
            .event-name {
                font-size: 3rem;
                margin-top: 0;
                word-break: break-word;
            }
            .event-date {
                font-size: 1.5rem;
                font-weight: 300;
                .datetime-element {
                    padding-right: 10px;
                    &:last-of-type {
                        padding-right: 0;
                    }
                }
            }
            .event-location {
                font-weight: 300;
                font-size: 1.3rem;
            }
        }
        .event-price-block(1.5rem);
        .btn {
            margin-top: 20px;
            padding: 10px 20px;
        }
    }
}
