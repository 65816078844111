@media (max-width: @screen-lg) {
    .event-search-form {
        .form-container {
            .form-inline {
                .form-group {
                    display: block;
                    padding-right: 0 !important;
                    .input-group {
                        margin-bottom: 15px;
                        width: 100%;
                        .input-group-addon {
                            width: 1%;
                        }
                        .form-control {
                            width: 100% !important;
                        }
                        .select2 {
                            width: 100% !important;
                        }
                    }
                    .error-message {
                        position: initial !important;
                    }
                    &.search-field-search {
                        float: none !important;
                        .input-group {
                            .search-field-search-filter {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: @screen-lg) {
    #event-search-form {
        display: block;
    }
}

@media (max-width: @screen-md) {
    .event-search-form {
        &.select2-dropdown {
            min-width: auto;
        }
    }
}
