.single-event-wrapper {
    padding-bottom: 15px;
    @media (hover) {
        &:hover {
            .button-wrapper {
                display: inherit;
            }

            .event-short-info {
                display: none;
            }
        }
    }
    a {
        color: @text-color;
        text-decoration: none;
    }
}
.single-event {
    padding: 15px;
    position: relative;
    .event-name {
        border-bottom: 1px dashed @brand-tickets-default-darken;
        font-weight: 700;
        height: 80px;
        line-height: 2.4rem;
        overflow: hidden;
        padding-bottom: 10px;
        padding-top: 20px;
        margin: 0;
        font-size: inherit;
        text-overflow: ellipsis;
    }
    .event-short-info {
        font-weight: 300;
        padding-top: 10px;
        height: 50px;
        .short-info-datetime {
            font-size: 1.3rem;
            padding-bottom: 5px;
            .datetime-element {
                padding-right: 15px;
                &:last-of-type {
                    padding-right: 0;
                }
            }
            .alternate-datetime-element {
                display: block;
                .ellipsis();
            }
        }
    }
    .event-location {
        font-size: 1.1rem;
        .event-location-description {
            overflow: hidden; 
            text-overflow: ellipsis; 
            white-space: nowrap; 
            word-break: break-all;
        }
    }
    .button-wrapper {
        display: none;
        padding-top: 15px;
        .btn {
            width: 100%;
            height: 35px;
        }
    }
    .event-price-block(1.1rem);
    .event-price-block {
        background: #FFFFFF;
        padding: 5px 15px;
        position: absolute;
        right: 15px;
        top: -30px;
    }
}

.rtl {
    .single-event-wrapper {
        .event-short-info {
            .event-location {
                text-align: right;
                direction: ltr;
            }
        }
    }
}
