@footer-row-padding: 30px;
@footer-text-padding: 10px;

.footer {
    width: 100%;
    .footer-row {
        padding: @footer-row-padding 0;
        .footer-column {
            border-right: 1px dashed @brand-tickets-default-darken;
            overflow: hidden;
            &:last-of-type {
                border-right: none;
            }
            .footer-title {
                text-transform: uppercase;
                font-weight: 700;
                font-size: 2rem;
                padding-bottom: @footer-text-padding;
            }
            .footer-info {
                a {
                    color: @text-color;
                    &:hover, &:focus, &:active, &:visited, &:link {
                        text-decoration: none;
                    }
                }
                i {
                    &:hover {
                        color: #23527c;
                    }
                }
            }
        }
    }
}
