@media (max-width: @screen-sm) {
    .event-information {
        .timeout-timer-container {
            float: none !important;
            text-align: center;
        }
        .event-basic-info {
            .info-label {
                overflow: visible;
                white-space: normal;
            }
        }
    }
}
