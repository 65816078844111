.top-items-title {
    text-transform: uppercase;
    padding: 2rem 0;
    color: @brand-tickets-secondary;
    font-size: 17px;
    .typography-top {
        font-weight: bold;
    }
    .typography-icon {
        font-size: 10px;
        vertical-align: middle;
        padding-bottom: 3px;
    }
    a {
        color: @brand-tickets-secondary;
    }
}
