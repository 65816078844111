.clear-field-wrapper {
    position: relative;
    .clear-icon {
        position: absolute;
        right: 10px;
        top: 5px;
        color: grey;
        font-size: 17px;
        &:hover {
            cursor: pointer;
        }
    }
}

.rtl {
    .clear-field-wrapper {
        .clear-icon {
            right: auto;
            left: 10px;
        }
    }
}
