@media (max-width: @screen-lg) {
    .carousel-caption {
        position: absolute;
        .gallery-event {
            padding: 15px 30px;
            text-align: center;
            margin-bottom: 40px;
            background: rgba(255, 255, 255, 0.9);
            min-height: 60px;
            line-height: 60px;
            .event-short-info {
                vertical-align: middle;
                display: inline-block;
                min-height: auto;
                line-height: 1em;
                .event-name {
                    font-size: 2rem;
                    margin-top: 0;
                    margin-bottom: 5px;
                    .ellipsis();
                }
                .event-date {
                    font-size: 1.2rem;
                    font-weight: 300;
                    text-align: center;
                    .datetime-element {
                        &:last-of-type {
                            padding-right: 0;
                        }
                    }
                }
            }
            .event-price-block(1.1rem);
            .event-price-block {
                text-align: right;
                vertical-align: middle;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 1rem;
                line-height: 1em;
            }
            .btn {
                display: none;
            }
        }
    }
}

@media (max-width: @screen-xs) {
    .carousel-caption {
        .gallery-event {
            .event-short-info {
                padding-right: 0;
                text-align: center;
                .event-name {
                    font-size: 1.5rem;
                }
                .event-date {
                    margin: 0;
                    text-align: center;
                }
            }
            .event-price {
                display: none;
            }
        }
    }
}
