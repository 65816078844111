.container {
    .static-page {
        padding-top: 40px;
        h1 {
            padding-bottom: 20px;
        }
        .terms {
            .term {
                padding-bottom: 20px;
            }
        }
        .steps {
            li {
                padding: 10px;
            }
        }
        .text-section {
            padding-top: 30px;
            padding-bottom: 30px;
        }

        .fb-widget {
            text-align: center;
            font-size: 3.5em;
            a {
                color: @text-color;
                &:hover, &:focus, &:active, &:visited, &:link {
                    text-decoration: none;
                }
            }
            i {
                margin-right: 9px;
                &:hover {
                    color: #23527c;
                }
            }
        }
    }
}
