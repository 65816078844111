.ellipsis() {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.event-price-block(@text-size) {
    .event-price-block {
        text-align: right;
        .price-title {
            font-size: @text-size;
        }
        .price-value {
            font-size: @text-size * 2;
            font-weight: 700;
            color: @brand-tickets-price;
            line-height: @text-size * 2;
        }
    }
}

.event-basic-info() {
    .info-item {
        margin-bottom: 3px;
        font-size: 1.3rem;
    }
    .info-label {
        margin-top: 0;
        margin-bottom: 15px;
        font-weight: 700;
    }
    .event-details,
    .event-organizer {
        padding-bottom: 20px;
    }
    .fa {
        width: 15px;
    }
}

.dashed-trailing-line() {
    width: 100%;
    position: relative;
    z-index: 1;
    &:before {
        border-top: 1px dashed @brand-tickets-default-darken;
        content:"";
        position: absolute;
        top: 50%;
        width: 100%;
        z-index: -1;
    }
    > * {
        background: @brand-tickets-default;
        padding-right: 10px;
    }
}

.default-inner-wrapper() {
    margin: 2rem 0 @default-bottom-spacing;
    background: @brand-tickets-default;
    padding: 3rem 0;
}

.row-even() {
    .row-even {
        background: @brand-tickets-default;
    }
}

.title-element-texts(@line-height) {
    .title-element {
        font-weight: 700;
        &.text-item {
            line-height: 3em;
        }
    }
    .text-item {
        line-height: @line-height;
    }
}

.modal-backdrop() {
    background-color: rgba(0, 0, 0, 0.5);
}
