.single-event-info {
    padding: 2rem 0;
    .event-details-wrapper {
        background: @brand-tickets-default;
        padding: 15px;
        .info-label {
            .dashed-trailing-line();
        }
        .event-organizer {
            .event-organizer-title {
                font-weight: 700;
            }
            .event-basic-info();
        }

        .event-price-block(1.1rem);
        .event-basic-info {
            margin-bottom: 15px;
            .event-basic-info();
        };
    }
    .event-group-details-wrapper {
        background: @brand-tickets-default;
        padding: 15px;
        margin-top: 2rem;
        .info-label {
            .dashed-trailing-line();
        }
        a {
            color: inherit;
        }
        .btn {
            color: white;
        }
        .event-group-details-scroll {
            overflow-x: hidden;
            overflow-y: scroll;
            max-height: 500px;
        }
        .grouped-event-container {
            min-height: 110px;
            background: white;
            margin-bottom: 15px;
            display: flex;
            .grouped-event-image {
                width: 30%;
                object-fit: cover;
            }
            .grouped-event-info {
                margin: 5px;
                width: 70%;
                .grouped-event-primary {
                    font-weight: 700;
                    .ellipsis();
                }
                .grouped-event-secondary {
                    font-size: 1.2rem;
                    margin-top: 5px;
                    font-weight: 300;
                }
                .grouped-event-price {
                    margin-top: 10px;
                    .event-price-block {
                        .price-title {
                            text-transform: capitalize;
                            display: inline-block;
                        }
                        .price-value {
                            font-weight: 700;
                            display: inline-block;
                            font-size: 1.8rem;
                            color: @brand-tickets-price;
                        }
                    }
                }
            }
        }
    }
    .event-description-wrapper {
        padding: 15px 0;
        .event-name {
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .event-description {
            word-break: break-word;
        }
    }
    .location-wrapper {
        .location-address {
            padding-left: 15px;
        }
        .event-map {
            margin-top: 15px;
        }
        .panel-body {
            padding-left: 0;
            padding-bottom: 0;
            padding-right: 0;
        }
    }
    .info-label {
        margin-top: 0;
        margin-bottom: 15px;
        font-weight: 700;
        font-size: 2.4rem;
    }
    .ticket-refund-rules {
        font-weight: bold;
        white-space: pre-line;
    }
}
