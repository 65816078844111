.user-callback-wrapper {
    .default-inner-wrapper();
    .ticket-list {
        .ticket-link {
            padding: 1rem 0;
        }
    }
    .organizer-info {
        padding-top: 2rem;
        display: inline-block;
        span {
            display: block;
            text-align: left;
            .fa {
                width: 15px;
            }
        }
    }
}

.rtl {
    .user-callback-wrapper {
        .organizer-info {
            span {
                text-align: right;
            }
        }
    }
}
