body {
    font-family: 'Roboto';
}

body.rtl {
    font-family: 'Amiri'
}

a {
    &:focus {
        outline: none;
    }
}

.alert-wrapper {
    .alert {
        margin-bottom: 0;
        margin-top: 20px;
    }
}
.bold {
    font-weight: 700;
}
