@default-form-group-distance: 3rem;

.user-details-fill-wrapper {
    .default-inner-wrapper();
    .disclaimer {
        padding-bottom: 2rem;
    }
    .user-details-form {
        .email {
            margin-bottom: @default-form-group-distance;
        }
        .login-redirect {
            text-align: right;
        }
        .records {
            .order_fields,
            .ticket_fields {
                > * {
                    > .form-group {
                        margin-bottom: @default-form-group-distance;
                        > .ticket-fields-collection-label {
                            .dashed-trailing-line();
                            .ellipsis();
                            font-size: 1.4em;
                        }
                    }
                }
            }
        }
        .continuation-disclaimer {
            padding-top: 25px;
            font-size: 0.85em;

            .organizer-disclaimer {
                padding-bottom: 20px;
            }

            .organizer-contact {
                padding-bottom: 10px;
            }
        }
    }
}

.rtl {
    .user-details-fill-wrapper {
        .user-details-form {
            input[type=email] {
                direction: ltr;
                text-align: right;
            }
        }
    }
}
