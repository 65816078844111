.btn-tickets-main {
    .button-variant(@btn-tickets-main-color; @btn-tickets-main-bg; @btn-tickets-main-border);
}
.btn-tickets-main-mini {
    .button-variant(@btn-tickets-main-color; @btn-tickets-main-bg; @btn-tickets-main-border);
    height: 30px;
    font-size: 12px;
}
.btn-tickets-main-inverted {
    .button-variant(@btn-tickets-main-bg; @btn-tickets-main-color;  @btn-tickets-main-border);
}

.btn-tickets-dark-inverted {
    .button-variant(@brand-tickets-number-four; @btn-tickets-main-color;  @brand-tickets-number-four);
}

.btn-tickets-gray {
    .button-variant(@btn-tickets-gray-color, @btn-tickets-gray-bg, @btn-tickets-gray-border);
}

.text-tickets-main {
    .text-emphasis-variant(@brand-tickets-main);
}

.text-tickets-number-four {
    .text-emphasis-variant(@brand-tickets-number-four);
}

.text-tickets-secondary {
    .text-emphasis-variant(@brand-tickets-secondary);
}

.bg-tickets-default {
    .bg-variant(@brand-tickets-default);
}

.panel-tickets-default-darken {
    .panel-variant(@brand-tickets-default-darken; @panel-default-text; @panel-default-heading-bg; @panel-default-border);
}
