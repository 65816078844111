.event-information {
    padding: 2em 0;
    .panel {
        margin-bottom: 0;
    }
    .event-basic-info {
        .event-basic-info();
        .event-date {
            display: inline-block;
            padding-right: 1em;
        }
        .event-time {
            display: inline-block;
            .fa {
                width: auto;
            }
        }
        .info-label {
            .ellipsis();
            font-size: 2.4rem;
        }
    }
    .timeout-timer-container {
        padding-top: 2.6em;
        .timer-time {
            font-weight: 700;
        }
    }
}

.rtl {
    .event-information {
        .event-date {
            padding-left: 1em;
            padding-right: 0;
        }
    }
}
