.header-row {
    height: 80px;
    line-height: 80px;
    .header-element {
        display: inline-block;
        vertical-align: middle;
        .self-service-button {
            margin-right: 20px;
        }
    }
    .header-img {
        width: 170px;
    }
}
