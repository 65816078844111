.ticket-type-discount-wrapper {
    margin: 0;
    padding-bottom: @default-bottom-spacing;
    .unavailable-ticket-type {
        .unavailability-description {
            color: @brand-tickets-secondary;
            font-style: italic;
        }
    }
    .ticket-type-info {
        .text-item {
            &:not(:only-child) {
                line-height: 1.2em;
            }
        }
        .ticket-type-description {
            font-size: 1.3rem;
            color: @brand-tickets-secondary;
        }
    }
    .price-type-info {
        .text-item {
            &:not(:only-child) {
                line-height: 1.2em;
            }
        }
        .price-type-select {
            max-width: 250px;
        }
        .price-type-description-block {
            max-width: 250px;
            padding-top: 5px;
            .price-type-description {
                font-size: 1.3rem;
                color: @brand-tickets-secondary;
                line-height: 1.5;
                word-break: break-all;
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}
