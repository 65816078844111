.seat-selection-wrapper {
    padding-top: 1em;
    padding-bottom: @default-bottom-spacing;
    .sector-name {
        font-weight: 700;
    }
    .sector-list {
        margin: 0;
        a {
            color: @text-color;
            text-decoration: none;
            &:hover {
                font-weight: 700;
            }
        }
    }
    .matrix {
        svg {
            .center-block();
            overflow: visible;
        }
        .seat {
            &.show.free {
                fill: @brand-tickets-default;
                cursor: pointer;
            }
            &.show.taken {
                fill: @brand-tickets-number-three;
                text {
                    fill: @brand-tickets-default;
                }
                cursor: default;
            }
            &.show.reserved {
                fill: @brand-tickets-main !important;
                cursor: pointer;
            }
        }
    }
    .ticket-type-map {
        padding: 1em 0;
        .circle {
            .square(20px);
            border-radius: 100%;
            display: inline-block;
            vertical-align: middle;
            background-color: @brand-tickets-default;
            border: 1px solid black;
            &.circle-taken {
                background-color: @brand-tickets-number-three;
            }
        }
    }
    .row {
        .step-actions {
            padding-left: @grid-gutter-width / 2;
            padding-right: @grid-gutter-width / 2;
        }
    }
    .cart-information {
        padding-top: 3em;
        .cart-wrapper {
            .row {
                margin: 0;
            }
        }
    }
}
