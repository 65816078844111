.breadcrumbs {
    color: @brand-tickets-secondary;
    counter-reset: step-counter;
    display: table;
    list-style: outside none none;
    padding: 2rem 0;
    table-layout: fixed;
    width: 100%;
    font-size: 1em;
    > li {
        display: table-cell;
        position: relative;
        text-align: center;
        &::before {
            border-radius: 100%;
            content: counter(step-counter, decimal);
            counter-increment: step-counter;
            display: block;
            font-size: 1.5em;
            height: 2em;
            line-height: 2em;
            margin: 0 auto 0.25em;
            position: relative;
            text-align: center;
            width: 2em;
            background: @brand-tickets-default;
        }
        &::after {
            content: "";
            display: block;
            height: 0.1em;
            left: 50%;
            position: absolute;
            top: 1.45em;
            width: 100%;
            z-index: -1;
            background: @brand-tickets-default;
        }
        &:last-child {
           &::after {
              display: none;
           }
        }
        &.is-complete {
            &::after {
                color: #fff;
                background: @breadcrumbs-completed-color;
            }
            &::before {
                color: #fff;
                background: @breadcrumbs-completed-color;
            }
        }
        &.is-active {
            &::before {
                background: @breadcrumbs-active-color;
                color: #fff;
            }
        }
        span {
            color: @brand-tickets-secondary;
        }
    }
}

.rtl {
    .breadcrumbs {
        > li {
        &::after {
                content: "";
                display: block;
                height: 0.1em;
                left: 50%;
                position: absolute;
                top: 1.45em;
                width: 100%;
                z-index: -1;
                background: @brand-tickets-default;
            }
            &:first-child {
                &::after {
                    display: none;
                }
            }
            &.is-complete {
                &::after {
                    color: #fff;
                    background: @breadcrumbs-completed-color;
                }
                &::before {
                    color: #fff;
                    background: @breadcrumbs-completed-color;
                }
            }
            &.is-active {
                &::before {
                    background: @breadcrumbs-active-color;
                    color: #fff;
                }
                &::after {
                    color: #fff;
                    background: @breadcrumbs-completed-color;
                }
            }
        }
    }
}
