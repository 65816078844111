@media (max-width: @screen-lg) {
    .ticket-type-discount-wrapper {
        .price-type-info {
            .price-type-select {
                max-width: 220px;
            }
            .price-type-description-block {
                max-width: 220px;
            }
        }
    }
}

@media (max-width: @screen-md) {
    .ticket-type-discount-wrapper {
        .unavailable-ticket-type {
            .unavailability-description {
                text-align: left;
            }
        }
        .price-type-info {
            .price-type-select {
                max-width: 140px;
            }
            .price-type-description-block {
                max-width: 140px;
            }
        }
    }
}
